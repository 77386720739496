import React, { Component } from "react";
import { THEMES } from "../context/withApp";
import Page from "../components/Page/Page";
import SEO from "../components/SEO/SEO";
import TeaserTitle from '../components/UI/Teaser/TeaserTitle';
import ScrollReveal from '../components/UI/ScrollReveal/ScrollReveal';
import Button from '../components/UI/Button/Button';
import DataStream from '../components/DataStream/DataStream';
import styles from './index.module.scss';

class IndexPage extends Component {
    render() {
        const windowWidth = typeof window !== 'undefined' && window.innerWidth;
        const windowHeight = typeof window !== 'undefined' && window.innerHeight;

        return (
            <Page { ...this.props.page} theme={ THEMES.dark }>
                <div className={styles.container}>
                    <SEO title="Freelance Digital Design & Front-end Developer in Southampton, Hampshire" keywords={[`digital`, `design`, `front-end development`, `freelance`, `portfolio`, `southampton`, `hampshire`]}/>

                    <div className={styles.body}>
                       
                        <ScrollReveal animation="blockWipe" delay={0}>
                            <TeaserTitle>Welcome</TeaserTitle>
                        </ScrollReveal>
                        
                        <ScrollReveal animation="slideUp" delay={0.33}>
                            <h1 className={styles.heading}>
                                Hey, i'm Pete Luffman, the go-to digital design & front-end specialist for brands and agencies who accept no compromise.
                            </h1>
                        </ScrollReveal>
                       
                        <ScrollReveal animation="slideUp" delay={0.66}>
                            <Button to="/work/">View work</Button>
                        </ScrollReveal>
                    </div>

                    {/* <div className={styles.lights}>
                       <div className={styles.light1}></div>
                       <div className={styles.light2}></div>
                       <div className={styles.light3}></div>
                    </div> */}

                    <DataStream className={styles.canvas} stream={[
                        {
                            speed : 15,
                            angle : 315,
                            bitCount : 20,
                            // bitCount : 1,
                            streamWidth: windowWidth * 0.1,
                            edge: 'top',
                            position : 0.2,
                            bitSize : {
                                maxWidth: windowWidth / 70,
                                maxHeight: windowHeight / 3,
                                minWidth: 1,
                                minHeight: 5
                            }
                        },
                        {
                            speed : windowWidth < 768 ? 10 : 25,
                            angle : 45,
                            bitCount : windowWidth < 768 ? 30 : 60,
                            streamWidth: windowWidth * 0.1,
                            edge: 'bottom',
                            position : 0.3,
                            bitSize : {
                                maxWidth: 10,
                                maxHeight: windowHeight / 3,
                                minWidth: 1,
                                minHeight: 3
                            }
                        }
                    ]} magnet={500}/>
                </div>
            </Page>
        )
    }
}

export default IndexPage;
