import paper from 'paper/dist/paper-core';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';

const DataStream = function(){
    var settings, view, scope, Path, Point;

    var defaults = {
        debug: false,
        maxFast: 3,
        magnet: 500,
        color: null
    };

    var fastCount = 0;
    var mouse = { x : 0, y : 0 };
    var streams = [];
    var magnetForce;
    var strokeColor;
    var frameCounter = 0;
    var fps = 0;
    var time = new Date();
    var isMouseOver = false;
    var _setCanvasSize;

    function getPos(pos, prevPos){
        var forcex = 0;
    	var forcey = 0;
        var x0, y0, x1, y1, distance, distancex, distancey, powerx, powery;

        x0 = pos.x;
        y0 = pos.y;
        x1 = mouse.x;
        y1 = mouse.y;
        distancex = x1-x0;
        distancey = y1-y0;

        distance = Math.sqrt((distancex * distancex) + (distancey * distancey));

        powerx = x0 - (distancex / distance) * magnetForce / distance;
        powery = y0 - (distancey / distance) * magnetForce / distance;

        forcex = (forcex + (prevPos.x - x0) / 2) / 2.1;
        forcey = (forcey + (prevPos.y - y0) / 2) / 2.1;

        return {
            distance: distance,
            x : (powerx + forcex),
            y : (powery + forcey)
        };
    }

    function addStream(options){
        var stream = [];
        var bitSize = options.bitSize;
        var viewportMax = Math.max(view.size.width, view.size.height);

        var flowDirection = {
            right: (options.angle > 0 && options.angle < 90) || (options.angle > 270 && options.angle < 360),
            left : (options.angle > 90) && (options.angle < 270),
            top : (options.angle > 0) && (options.angle < 180),
            bottom : (options.angle > 180) && (options.angle < 360),

            topRight : (options.angle >= 0) && (options.angle <= 90),
            topLeft : (options.angle >= 90) && (options.angle <= 180),
            bottomRight : (options.angle >= 270) && (options.angle <= 360),
            bottomLeft : (options.angle >= 180) && (options.angle <= 270)
        };

        // Generate bits in random positions in the view:
        for (var i = 0; i < options.bitCount; i++) {
            var startPoint = {};
            var pathHeight = getRandomArbitrary(bitSize.minHeight, bitSize.maxHeight);
            var streamOffset = options.streamWidth / 2;
            var path = new Path({
            	strokeColor: options.color,
            	strokeWidth: getRandomArbitrary(bitSize.minWidth, bitSize.maxWidth),
                strokeCap: 'round'
            });

            path.add( new Point(0, 0) );
            path.add( new Point(pathHeight, 0) );

            switch (options.edge) {
                case 'left':
                    startPoint.x = 0 - pathHeight;
                    startPoint.y = getRandomArbitrary((view.size.height * options.position) - streamOffset, (viewportMax * options.position) + streamOffset);
                    break;
                case 'right':
                    startPoint.x = view.size.width + pathHeight;
                    startPoint.y = getRandomArbitrary((view.size.height * options.position) - streamOffset, (viewportMax * options.position) + streamOffset);
                    break;
                case 'top':
                    startPoint.x = getRandomArbitrary((view.size.width * options.position) - streamOffset, (viewportMax * options.position) + streamOffset);
                    startPoint.y = -pathHeight;
                    break;
                case 'bottom':
                    startPoint.x = getRandomArbitrary((view.size.width * options.position) - streamOffset, (viewportMax * options.position) + streamOffset);
                    startPoint.y = view.size.height + pathHeight;
                    break;
                default:
                    throw new Error( `addStream(): flow direction '${options.edge}' not known.` );
            };
            
            var bitPos = getRandomArbitrary(0, viewportMax);
            var vectorX = startPoint.x + (bitPos * Math.cos(options.angle * Math.PI / 180));
            var vectorY = startPoint.y - (bitPos * Math.sin(options.angle * Math.PI / 180));

            if(Math.random() > 0.7 && fastCount < settings.maxFast) {
                path.speed = options.speed * getRandomArbitrary(0.7, 1);
                fastCount++;
            } else {
                path.speed = options.speed * getRandomArbitrary(0.1, 0.3);
            }

            // path.selected = true;
            path.rotate(-options.angle);

            path.strokeColor = 'rgb(' + [strokeColor.normal.r, strokeColor.normal.g, strokeColor.normal.b].join(',') + ')';
        	path.position = new Point(vectorX, vectorY);
            path.startPoint = startPoint;

            stream.push(path);
        }

        stream.angle = options.angle;
        stream.width = options.streamWidth;
        stream.flowDirection = flowDirection;

        streams.push(stream);

        view.draw();
    }

    function flow(stream){
        var flowDirection = stream.flowDirection;
        var i = stream.length;

        while (i--) {
            var item = stream[i];

            // Move the bit
            var vectorX = item.speed * Math.cos(stream.angle * Math.PI / 180);
            var vectorY = item.speed * Math.sin(stream.angle * Math.PI / 180);

            item.position.x += vectorX;
            item.position.y -= vectorY;

            // Handle mouse avoidance
            var offset = {
                x: item.startPoint.x - item.position.x,
                y: item.startPoint.y - item.position.y
            };

            var itemPos = getPos(item.position, {
                x :  item.startPoint.x - offset.x,
                y :  item.startPoint.y - offset.y
            });

            if( isMouseOver ) {
                item.position.x = itemPos.x;
                item.position.y = itemPos.y;
            }

            // Move bit to start if flowed offscreen
            if( 
                (flowDirection.bottomLeft && ( (item.bounds.right < 0) || (item.bounds.top > view.size.height) ))
                || (flowDirection.bottomRight && ( (item.bounds.left > view.size.width) || (item.bounds.top > view.size.height) ))
                || (flowDirection.topLeft && ( (item.bounds.right < 0) || (item.bounds.bottom < 0) ))
                || (flowDirection.topRight && ( (item.bounds.left > view.size.width) || (item.bounds.bottom < 0) )) 
            ) {
                item.position.x = item.startPoint.x;
                item.position.y = item.startPoint.y;
            }

            // Change colour on mouse proximity
            var pct = 100 * ( (itemPos.distance - 0) / (magnetForce - 0) );
            var color = pct >= 0 && pct <= 100 ? getColorStep( pct ) : strokeColor.normal;

            item.strokeColor = 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
        }
    }

    function setCanvasSize(event) {
        view.viewSize = new paper.Size( view.element.clientWidth, view.element.clientHeight );
    }

    function mapColors() {
        var normal = hexToRgb( settings.color.normal );
        var highlight = hexToRgb( settings.color.highlight );

        return {
            normal : merge({}, normal, rgbToHsl( normal.r, normal.g, normal.b ) ),
            highlight : merge({}, highlight, rgbToHsl( highlight.r, highlight.g, highlight.b ) )
        }
    }

    function getColorStep(percent) {
        var color = {};

        function average(col1, col2){
            return (percent * (col1 - col2) / 100) + col2;
        }

        if ( settings.color.useHSL ) {
            var h = average(strokeColor.normal.h, strokeColor.highlight.h);
            var s = average(strokeColor.normal.s, strokeColor.highlight.s);
            var l = average(strokeColor.normal.l, strokeColor.highlight.l);

            color = hslToRgb(h, s, l);
        } else {
            color = {
                r : average(strokeColor.normal.r, strokeColor.highlight.r),
                g : average(strokeColor.normal.g, strokeColor.highlight.g),
                b : average(strokeColor.normal.b, strokeColor.highlight.b)
            }
        }

        return color;
    }

    /**
    * Event handling
    */

    function onFrame( event ){
        var i = streams.length;

        while (i--) {
            flow(streams[i]);
        }

        if( settings.debug ) {
            ++frameCounter;
            var currentTime = new Date();
            var elapsedTimeMS = currentTime - time;
            if (elapsedTimeMS >= 1000)
            {
                fps = frameCounter;
                frameCounter = 0;
                time = currentTime;
            }

            console.log(fps);
        }
    }

    function stop() {
        view.detach('frame', onFrame);
    }

    function play() {
        view.onFrame = onFrame;
    }

    function onMouseMove( event ){
        var rect = view.element.getBoundingClientRect();

        mouse.x = event.clientX - rect.left;
        mouse.y = event.clientY - rect.top;
    }

    function onMouseOver( event ){
        var rect = view.element.getBoundingClientRect();

        if( event.clientY >= rect.top && event.clientY <= rect.bottom ) {
            isMouseOver = true;
        }
    }

    function onMouseOut( event ){
        isMouseOver = false;
        mouse.x = view.element.clientWidth / 2;
        mouse.y = view.element.clientHeight / 2;
    }

    function bindHandlers(){
        view.onFrame = onFrame;

        _setCanvasSize = debounce( () => setCanvasSize, 100 );

        window.addEventListener('resize', _setCanvasSize, false);
        window.addEventListener('mousemove', onMouseMove, false);
        window.addEventListener('mouseover', onMouseOver, false);
        window.addEventListener('mouseout', onMouseOut, false);
    }

    /**
    * Helpers
    */

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   Number  h       The hue
     * @param   Number  s       The saturation
     * @param   Number  l       The lightness
     * @return  Array           The RGB representation
     */
    function hslToRgb(h, s, l){
        var r, g, b;

        if(s === 0){
            r = g = b = l; // achromatic
        }else{
            var hue2rgb = function hue2rgb(p, q, t){
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        }
    }

    /**
     * Converts an RGB color value to HSL. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and
     * returns h, s, and l in the set [0, 1].
     *
     * @param   Number  r       The red color value
     * @param   Number  g       The green color value
     * @param   Number  b       The blue color value
     * @return  Array           The HSL representation
     */
    function rgbToHsl(r, g, b){
        r /= 255;
        g /= 255;
        b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if(max === min){
            h = s = 0; // achromatic
        }else{
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
                default: break;
            }
            h /= 6;
        }

        return {
            h: h,
            s: s,
            l: l
        }
    }

    return {
        init: function( canvas, options ){
            settings = merge({}, defaults, options);
            strokeColor = mapColors( settings.color );
            magnetForce = settings.magnet;
            mouse = {
                x : canvas.clientWidth / 2,
                y : canvas.clientHeight / 2
            };

            scope = new paper.PaperScope();
            scope.setup( canvas );

            view = scope.view;
            Path = scope.Path;
            Point = scope.Point;

            setCanvasSize();
            bindHandlers();
        },

        destroy: function(){
            stop();

            scope.project.activeLayer.removeChildren();
            view.draw();

            window.removeEventListener('resize', _setCanvasSize, false);
            window.removeEventListener('mousemove', onMouseMove, false);
            window.removeEventListener('mouseover', onMouseOver, false);
            window.removeEventListener('mouseout', onMouseOut, false);

            scope.remove();

            settings = strokeColor = scope = null;
            streams = [];
        },

        add: addStream,
        stop: stop,
        play: play
    }
};

export default DataStream;
