import React, { useEffect } from 'react';
import datastream from './paper-datastream';


const DataStream = ({ className, stream, ...options }) => {
    const ref = React.useRef(null);
    let ds;

    function render(){
        ds = new datastream();
        
        ds.init( ref.current, {
            ...options
        });

        stream.forEach( item => ds.add( item ) );
    }
    
    function handleResize(){
        ds.destroy();
        render();
    }

    useEffect(() => {
        if( typeof window !== 'undefined') {
            render();
            window.addEventListener('resize', handleResize );
        }

        return () => {
            window.removeEventListener('resize', handleResize );
            ds.destroy();
        }
    });

    return (
        <canvas className={className} data-paper-hidpi="off" ref={ref}></canvas>
    )
};

DataStream.defaultProps = {
    magnet: 500,
    color: {
        useHSL: false,
        normal: '#F21999',
        highlight: '#3DB0F9'
    }
}
DataStream.propTypes = {
    
};

export default React.memo( DataStream, (prevProps, nextProps) => {
    return JSON.stringify( prevProps.stream ) === JSON.stringify( nextProps.stream ) 
        || JSON.stringify( prevProps.color ) === JSON.stringify( nextProps.color );
} );